import React from "react"
import Layout from "@components/layout"
import { GetPMLP } from "@components/getpowermetrics/get-pm-lp"

const IndexMicrosite = () => {
  return (
    <Layout
      titleExtension="Klipfolio PowerMetrics"
      seo={{
        title: "Make Analytics Everybody’s Business",
        description: `PowerMetrics helps data teams unlock the value in their data stack and make working with metrics effortless for everyone. Provide a catalog of trusted metrics.`,
      }}
      fullWidth
      marginless
      hideOverflow
      microsite
    >
      <GetPMLP />
    </Layout>
  )
}

export default IndexMicrosite
